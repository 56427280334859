<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="400"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--title">
        {{ formType === 'ADD' ? $t('belirumaUser.addNote') : $t('belirumaUser.editNote') }}
      </div>
      <form @submit.prevent="submit">
        <div class="form--group row" :class="{ 'has-error': validation.hasError('text') }">
          <label class="col-12 col-lg-4">Isi Catatan</label>
          <div class="col-12 col-lg-8">
            <v-textarea
              id="noteTextField"
              v-model="text"
              type="text"
              name="notes[]"
              outlined
              hide-details
              placeholder="Isi Catatan"
              class="mb-2"
              rows="5"
            />
            <span class="val-error">{{ validation.firstError('text') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('tag') }">
          <label class="col-12 col-lg-4">Tipe Catatan</label>
          <div class="col-12 col-lg-8">
            <multiselect
              name="selectedTag"
              id="selectedTagField"
              v-model="tag"
              :options="tags"
              :close-on-select="true"
              track-by="id"
              label="name"
              placeholder="Pilih Tipe"
              deselect-label=""
              select-label=""
            />
            <span class="val-error">{{ validation.firstError('tag') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-center mt-4">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ formType === 'ADD' ? $t('belirumaUser.addNote') : $t('belirumaUser.editNote') }}
          </v-btn>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'modal-note',
  mixins: [HelperMixin],
  components: { Multiselect },
  data() {
    return {
      modalName: 'modal-note',
      note: null,
      text: null,
      tag: null,
      dispatchAfterString: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.belirumaUser.form.loading,
      formType: (state) => state.belirumaUser.form.noteFormType,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      tags: (state) => state.belirumaUser.form.tags,
    }),
  },
  validators: {
    text(value) {
      return Validator.value(value).required(this.$i18n.t('errors.belirumaUser.noteText.required'));
    },
    tag(value) {
      return Validator.value(value).required(this.$i18n.t('errors.belirumaUser.noteTag.required'));
    },
  },
  methods: {
    beforeOpen(event) {
      if (event.params.note) {
        this.note = event.params.note;
        this.text = this.note.text;
        this.tag = this.note.tag;
      } else {
        this.note = null;
        this.text = null;
        this.tag = null;
      }
      this.dispatchAfterString = event.params.dispatchAfterString;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    addNote() {
      this.$store.commit('belirumaUser/form/ADD_NOTE');
    },
    async submit() {
      const isValid = await this.$validate();
      if (isValid) {
        try {
          let note = this.note ? this.note : {};
          note.text = this.text;
          note.tag = this.tag;
          if (this.formType === 'ADD') {
            await this.$store.dispatch('belirumaUser/form/addNote', note);
          } else {
            await this.$store.dispatch('belirumaUser/form/editNote', note);
          }
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t(
              this.formType === 'ADD'
                ? 'belirumaUser.createNote.successMsg'
                : 'belirumaUser.updateNote.successMsg',
            ),
            'success',
          );
          this.close();
          if (this.dispatchAfterString) {
            await this.$store.dispatch(this.dispatchAfterString, this.$route.params.uuid);
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.log('ERROR NOTE FORM: ', e);
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
